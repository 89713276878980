import UserService from "@/services/user.service";

const initialState = {profile: null};

export const user = {
  namespaced: true,
  state: initialState,
  actions: {
    usersMe({commit}) {
      return UserService.usersMe().then(
        profile => {
          commit('usersMeSuccess', profile);
          return Promise.resolve(profile);
        },
        error => {
          return Promise.reject(error);
        }
      )
    }
  },
  getters: {
    getFullname: state => {
      if (state.profile != null) {
        if (state.profile.last_name) {
          return `${state.profile.first_name}` + " " + `${state.profile.last_name}`;
        } else {
          return `${state.profile.first_name}`;
        }
      }
    },
    getFirstName: state => {
      if (state.profile != null) {
        return `${state.profile.first_name}`;
      }
    },
    getBoostPack: state => {
      if (state.profile != null && state.profile.current_boost_pack != null) {
        return state.profile.current_boost_pack;
      } else {
        return null
      }
    }
  },
  mutations: {
    usersMeSuccess(state, profile) {
      state.profile = profile;
    },
    resetState(state) {
      Object.assign(state, initialState);
    }
  }
};
