import {createRouter, createWebHistory} from 'vue-router'
import AnonymousLogin from "@/views/AnonymousLoginView";
import NotFound from "@/views/NotFoundView";
import Dashboard from "@/views/Coaching/DashboardView";
import OAuthCallbackView from "@/views/OAuthCallbackView";
import TokenService from "@/services/token.service";
import AuthService from "@/services/auth.service";
import store from '@/store';
import HomeRedirection from "@/views/HomeRedirection";

const routes = [
  {
    path: '/',
    name: 'HomeRedirection',
    component: HomeRedirection
  },
  {
    path: '/login',
    name: 'Login',
    component: AnonymousLogin,
    meta: {
      accessGrantedForUser: true,
    },
    beforeEnter: (to, from, next) => {
      if (store.state.auth.anonymousUserIdentifier) {
        next({'name': 'Meeting'})
      }
      next()
    }
  },
  {
    path: '/meeting',
    name: 'Meeting',
    meta: {
      accessGrantedForAnonymous: true,
      accessGrantedForBoostPack: true
    },
    component: () => import('@/views/Meeting/MeetingView.vue'),
  },
  {
      path: '/meeting-criterias/:id',
      name: 'MeetingCriterias',
      meta: {
          accessGrantedForAnonymous: true,
          accessGrantedForBoostPack: false
      },
      component: () => import('@/views/Meeting/MeetingCriteriasView.vue'),
  },
  {
    path: '/meeting-validation',
    name: 'meetingValidation',
    meta: {
      accessGrantedForAnonymous: true,
      accessGrantedForBoostPack: true
    },
    component: () => import('@/views/Meeting/MeetingValidationView.vue')
  },
  {
    path: '/meeting/:id',
    name: 'MeetingConfirmation',
    meta: {
      accessGrantedForAnonymous: true,
      accessGrantedForBoostPack: true
    },
    component: () => import('@/views/Meeting/MeetingConfirmationView.vue')
  },
  {
    path: '/boostpack/coaches/',
    name: 'BoostPackCoachSelection',
    meta: {
      accessGrantedForBoostPack: true
    },
    component: () => import('@/views/BoostPack/ChooseCoach.vue')
  },
  {
    path: '/boostpack/dashboard/',
    name: 'BoostPackDashboard',
    meta: {
      accessGrantedForBoostPack: true
    },
    component: () => import('@/views/BoostPack/BoostPackDashboard.vue')
  },
  {
    path: '/boostpack/meetings/',
    name: 'BoostPackMeeting',
    meta: {
      accessGrantedForBoostPack: true
    },
    component: () => import('@/views/BoostPack/BoostPackMeeting.vue')
  },
  {
    path: '/boostpack/criterion/',
    name: 'BoostPackCriterion',
    meta: {
      accessGrantedForBoostPack: true
    },
    component: () => import('@/views/BoostPack/BoostPackCriterion.vue')
  },
  {
    path: '/coaching/calendar',
    name: 'CoachingCalendar',
    meta: {
      accessGrantedForCoach: true
    },
    component: () => import('@/views/Coaching/CalendarView.vue')
  },
  {
    path: '/coaching/availability',
    name: 'Availability',
    meta: {
      accessGrantedForCoach: true
    },
    component: () => import('@/views/Coaching/AvailabilityView.vue')
  },
  {
    path: '/coaching/days-off',
    name: 'DaysOff',
    meta: {
      accessGrantedForCoach: true
    },
    component: () => import('@/views/Coaching/DaysOffView.vue')
  },
  {
    path: '/coaching/invoices',
    name: 'CoachingInvoiceList',
    meta: {
      accessGrantedForCoach: true
    },
    component: () => import('@/views/Coaching/InvoiceListView.vue')
  },
  {
    path: '/coaching/meeting/:id',
    name: 'MeetingDetail',
    meta: {
      accessGrantedForCoach: true
    },
    component: () => import('@/views/Coaching/MeetingView.vue')
  },
  {
    path: '/coaching/meetings/anonymous/:id',
    name: 'CoachMeetingAnonymousHistory',
    meta: {
      accessGrantedForCoach: true
    },
    component: () => import('@/views/Coaching/AnonymousMeetingList.vue')
  },
  {
    path: '/coaching/meetings/boost-pack/:id',
    name: 'BoostPackMeetingList',
    meta: {
      accessGrantedForCoach: true
    },
    component: () => import('@/views/Coaching/BoostPackMeetingList.vue')
  },
  {
    path: '/coaching',
    name: 'Coaching',
    meta: {
      accessGrantedForCoach: true
    },
    component: Dashboard,
  },
  {
    path: '/oauth/callback',
    name: 'OAuthCallback',
    component: OAuthCallbackView,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound', component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  if (to.path === '/') {
    store.commit('meetingStore/resetState');
    store.commit('auth/resetState');

    if (TokenService.getToken()) {
      if (TokenService.isRoleUserAndIsAnonymousIdentified()) {
        next({name: 'Meeting'});
      } else if (TokenService.isRoleCoach()) {
        next({name: 'Coaching'});
      } else {
        this.$store.dispatch("user/usersMe").then(() => {
          if (this.$store.getters['user/getBoostPack']) {
            next({name: 'BoostPackDashboard'});
          } else {
            next({name: 'Login'});
          }
        });
      }
    } else {
      AuthService.redirect();
    }
  }

  if (to.name !== 'OAuthCallback' && TokenService.getToken() === null) {
    AuthService.redirect();
  }

  if(TokenService.getToken()) {
    switch (true) {
      case TokenService.isRoleUserAndIsAnonymousIdentified() && !to.meta.accessGrantedForAnonymous:
        next({name: 'Meeting'})
        break
      case TokenService.isRoleCoach() && !to.meta.accessGrantedForCoach:
        next({name: 'Coaching'})
        break
      case TokenService.isRoleUser() && store.getters['user/getBoostPack'] && !to.meta.accessGrantedForBoostPack:
        next({name: 'BoostPackDashboard'})
        break
      case TokenService.isRoleUser() && !TokenService.isRoleUserAndIsAnonymousIdentified() && !store.getters['user/getBoostPack'] && !to.meta.accessGrantedForUser:
        next({name: 'Login'})
        break
      default:
        next()
        break
    }
  } else {
    next()
  }

})

export default router
